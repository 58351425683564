
import { mapGetters } from "vuex";
import _ from "lodash";
import LazyHydrate from 'vue-lazy-hydration';
import responsiveCalcMixin from "@/mixins/responsive-calculator";
import responsiveMixin from "@/mixins/responsive";

import {
  hash2Obj,
  isObject,
  isSet,
} from "@/data/helpers";
import homeApi from "@/data/api/home-feed";
import collectionsApi from "@/data/api/collections";

export default {
  name: "VoicesModals",
  mixins: [responsiveCalcMixin, responsiveMixin],
  components: {
    LazyHydrate,
    NewPost: () => import('./Screens/NewPost'),
    BeforeYou: () => import('./Screens/BeforeYou'),
    NewPostAlert: () => import('@/components/NewPostAlert'),
    SuccessAlert: () => import("@/components/SuccessAlert"),
    PinAlert: () => import("@/components/PinAlert"),
    GroupAlert: () => import("@/community-details/GroupAlert"),
    FullPost: () => import("./Screens/FullPost"),
    DeletedPost: () => import("./Screens/DeletedPost"),
    ChangeDestination: () => import("./Screens/ChangeDestination"),
    PostBookmarkModal: () => import("@/components/PostBookMarkModal"),
    Registration: () => import("./Screens/Registration"),
    JoinToActionModal: () => import("@/components/JoinToActionModal"),
    ConfirmUnsaveModal: () => import("@/components/ConfirmUnsaveModal"),
    BadgeEarnedModal: () => import("@/components/BadgeEarnedModal"),
    BlockUserModal: () => import("@/components/BlockUserModal"),
    JoinCommunityModal: () => import("@/components/JoinCommunityModal"),
    PostAffirmationModal: () => import("@/components/Affirmations/PostAffirmationModal"),
    AffirmationModal: () => import("@/components/Affirmations/AffirmationModal"),
    CropperModal: () => import("@/components/CropperModal"),
    ContentGate: () => import("@/components/ContentGate"),
    SignUpPrompt: () => import("./Screens/SignUpPrompt"),
    JoinTheMighty: () => import("./Screens/JoinTheMighty"),
    CommunityOnboardingModal: () => import("@/community-details/CommunityOnboardingModal"),
    CommunityStoryModal: () => import("../../userExperienceRedesign/components/CommunityStoryModal"),
    AcceptPrivacyPolicy: () => import("@/privacy-request/AcceptPrivacyPolicy"),
    CollectionModal: () => import("@/feed/TopicFeedContainer/CollectionModal"),
  },
  props: ["loading"],
  data() {
    return {
      hydration: false,
    };
  },
  watch: {
    completeOnboarding: {
      immediate: true,
      handler() {
        if (this.completeOnboarding) {
          this.$store.dispatch("openModal", {
            id: "login-registration-onboarding",
            args: { action: "registration", completeOnboarding: true },
          });
        }
      },
    },
    openRegistration: {
      immediate: true,
      handler(val) {
        // stopping modal from opening twice
        if (val && this.openModal === undefined) {
          const action = "register" in this.$route.query ? "registration" : "login";
          this.$store.dispatch("openModal", {
            id: "login-registration-onboarding",
            args: { action },
          });
        }
      },
    },
    /*
      Within the CollectionModal, if a user clicks a feed card, the resulting
      Full Post modal will replace the CollectionModal. The below is essentially a
      hack so that when they close the Full Post Modal, we can open the prior 
      CollectionModal back up.
    */
    openModal: {
      immediate: true,
      handler(newval, oldval) {
        if (
          oldval === "view-full-post" &&
          newval === undefined &&
          this.getCurrentCollection._id
        ) {
          this.$store.dispatch("openModal", {
            id: "collection",
            args: {
              collection: this.getCurrentCollection,
            },
          });
        }
      },
    },
    openCookiesPreferenceCenter: {
      immediate: true,
      handler(shouldTrigger) {
        if (shouldTrigger) {
          this.openPreferenceCenter();
          // resetting the state to false
          this.$store.commit('setCookiesPreferenceCenter', false);
        }
      }
    },
    showContentGate: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            // if the open registration modal is open (ie. say the click on a link before hand),
            // need to add the gate for subsequent opening
            let func = this.isModal('login-registration-onboarding') ? 'addModal' : 'openModal';
            this.$store.dispatch(func, {
              id: "content-gate",
              args: {},
            });
          }, 7500);
        }
      }
    },
    hasBadges: {
      immediate: true,
      handler(val) {
        if (val && process.server) {
          // check to see if badges stored on cookies, and if so break down into a map for later
          const badges = this.$cookies.get('badges');
          let map = new Map();
          if (badges) {
            _.forEach(badges.split(';'), str => {
              let [badge, level, multiplier] = str.split(',');
              if (badge === '') return;
              map.set(badge, { level, multiplier });
            })
          }
          let str = '';
          _.forEach(this.badges, badge => {
            let level = badge.tier.level;
            let slug = badge.award.slug;
            str += `${slug},${level},${badge.multiplier};`;
            if (slug === 'comments') return;
            // if badge was not on cookie, or if they earned a higher level badge, show the badge
            if ((!map.has(slug) && level > 0) || (map.has(slug) && (+map.get(slug).level < badge.tier.level || +map.get(slug).multiplier < badge.multiplier))) {
              this.$store.dispatch('addModal', { 
                id: 'badge-earned', 
                args: { badge }
              })
            }
          })
          this.$cookies.set('badges', str);
        }
      }
    },
    "$route.params.contentId"() {
      this.loadFullPost();
    },
    "$route.params.affirmationId": {
      immediate: true,
      async handler(affirmationId) {
        if (!affirmationId) {
          return;
        }
        let args = {};
        await homeApi.getAffirmation(affirmationId, this.$nodeAxios)
          .then((res) => { 
            if (res.status === 404) {
              args.error = true;
              return;
            }
            args.affirmation = res;
          })
        this.$store.dispatch("openModal", {
          id: "affirmation",
          args,
        });
      }
    },
    "$route.path": {
      immediate: true,
      handler(val) {
        if (this.isModal('content-gate')) {
          if (!this.privatePath) this.$store.dispatch('closeModals');
        } else if (this.notLoggedIn && this.privatePath) {
          // only for non logged in users, incremement article read count
          this.handleContentGate();
        }
      },
    },
  },
  computed: {
    // load data from the global state handler
    ...mapGetters({
      user: "user",
      openModal: "getOpenModal",
      getAlertState: "getAlertState",
      getFeedItem: "getFeedItem",
      getContextView: "getContextView",
      getCurrentCollection: "getCurrentCollection",
      openCookiesPreferenceCenter: "openCookiesPreferenceCenter",
      storyContent: "storyContent",
      articlesRead: 'getArticleCount',
      badges: 'getMyBadges',
    }),
    notLoggedIn() {
      return _.isNil(this.user._id);
    },
    completeOnboarding() {
      return 'complete_onboarding' in this.$route.query;
    },
    registerUrl() {
      return "register" in this.$route.query || "login" in this.$route.query;
    },
    // waiting for the loading to finish so that at that point we can tell if the user is logged in or not
    openRegistration() {
      return !this.loading.pending && this.notLoggedIn && this.registerUrl;
    },
    isModalAcceptPrivacyPolicyOpen() {
      return this.$store.getters.isModalOpen("accept-privacy-policy");
    },
    embeddedBrowser() {
      if (process.browser) {
        // if one of the strings is in the user agent
        const browsers = ["FB", "Instagram", "Pinterest"];
        const userAgent = _.get(window, "navigator.userAgent", "");
        return _.some(browsers, (b) => userAgent.includes(b));
      }
    },
    isPrivacyPage() {
      return this.$route.path.includes("/privacy");
    },
    // paths where we will want to show the content gate
    privatePath() {
      let dirs = this.$route.path.split('/');

      // per a client request, disable the Content Gate for the below article
      if (dirs.includes('askme-qa-with-dr-stone-on-primary-hyperoxaluria-type-1-ph1')) return false;

      return ['content', 'topic', 'groups', 'u', 'partner'].includes(dirs[1]);
    },
    fromEmail() {
      return ['email', 'newsletter'].includes(_.get(this.$route, 'query.utm_medium'));
    },
    showContentGate() {
      return this.notLoggedIn && this.articlesRead >= 3 && this.privatePath && !this.fromEmail;
    },
    hasBadges() {
      return !this.notLoggedIn && this.badges.length > 0;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.loadFullPost();
    });
    this.loadFullPost();

    this.loadCollection();

    this.$gtm.push({ event: 'VoicesModalsLoaded' });

    setTimeout(() => {
      // set a timer for 10 seconds that opens the sign in prompt if user logged out and no other modal open
      if (
        this.notLoggedIn &&
        this.openModal === undefined &&
        this.embeddedBrowser
      ) {
        this.$store.dispatch("openModal", {
          id: "sign-up-prompt",
          args: {},
        });
      }
    }, 10000);

    this.showAcceptPrivacyModal();
  },

  // actions that need to occur inside this element
  methods: {
    // is the current modal the supplied value
    isModal(id) {
      return id === this.openModal;
    },
    // must do this here.. we need a better solution for this
    loadFullPost() {
      if (this.showContentGate) return;
      const pathname = window.location.pathname.split("/");
      let query = hash2Obj(window.location.hash.substr(1));
      const commentId = query.comment;
      if (!isSet(query.post) && pathname.indexOf("content") !== -1) {
        query = { post: pathname[pathname.indexOf("content") + 1] };
      } else if (!isSet(query.post) && pathname.indexOf("poll") !== -1) {
        query = { post: pathname[pathname.indexOf("poll") + 1] };
      }
      if (isObject(query) && isSet(query.post)) {
        this.$store
          .dispatch("loadSpecificItem", { postId: query.post })
          .then(() => {
            const post = this.getFeedItem(query.post);
            const isSuspended = _.get(post, 'author_id.profile.role') === 'suspended';
            if (isObject(post) && !isSuspended) {
              this.$store.dispatch("updateCurrentFullPost", {
                data: post,
                options: { commentId },
              });
              const modalType =
                post.type === "WORDPRESS_POST"
                  ? "view-full-story"
                  : "view-full-post";
              this.$store.dispatch("openModal", { id: modalType });
            } else {
              this.$store.dispatch("openModal", {
                id: "deleted-view-full-post",
              });
            }
          });
      }
    },

    loadCollection() {
      const pathname = window.location.pathname.split("/");
      // specifically for launching collection on homepage
      if (pathname[1] === "collection" && pathname[2]) {
        collectionsApi
          .collectionById(pathname[2], this.$nodeAxios)
          .then((collections) => {
            const collection = collections[0];
            let args = {
              callback: () => {
                history.pushState({}, "", "/");
              },
            };
            if (collection) {
              args.collection = collection;
            } else {
              args.error = true;
            }
            this.$store.dispatch("openModal", {
              id: "collection",
              args,
            });
          });
      }
    },

    showAcceptPrivacyModal() {
      // if legacy user who hasn't accept privacy policy, check if we need to reopen it
      if (
        _.get(this.user, "legacy_user", false) &&
        !_.get(this.user, "privacy_policy.accept_privacy_policy", false) &&
        !this.isPrivacyPage
      ) {
        const skippedPrivacyPolicy = window.localStorage.getItem(
          "skippedAcceptPrivacyPolicy"
        );
        if (skippedPrivacyPolicy) {
          const now = new Date();
          const dateDiff =
            (now - new Date(skippedPrivacyPolicy)) / (1000 * 60 * 60 * 24);
          // don't open if the user received the modal in the past 3 days
          if (dateDiff < 3) {
            return;
          }
        }
        this.$store.dispatch("openModal", {
          id: "accept-privacy-policy",
          args: {},
        });
      }
    },
    openPreferenceCenter() {
      if (this.$refs.cookie_settings) {
        this.$refs.cookie_settings.click();
      }
    },
    // Delay hydration until this component is needed.
    triggerHydration() {
      if(this.hydration ) {
        return true
      }
      // if openModal is not empty
      if(this.openModal) {
        this.hydration = true;
      } else {

        const alerts = ['new-post-alert', 'group-alert', 'success-alert', 'pin-alert'];
        // If there's an alert
        if(alerts.some(alert => this.getAlertState(alert))) {
          this.hydration = true;
        }
      }
      return this.hydration 
    },
    handleContentGate() {
      const n = this.articlesRead + 1;
      this.$cookies.set('articleCount', n);
      this.$store.commit('setArticleCount', n);
    },
  },
};

/**
 * @NOTE - v-if on each modal below makes it so that when the modal becomes active, the modal's mounted() function is called. otherwise, you cannot distinguish
 *         modal creation from modal data update
 */

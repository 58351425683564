
import { mapGetters } from 'vuex';
import segmentAnalytics from '@/libs/events';

export default {
    name: 'FeaturedCollections',
    data() {
        return {
            hovered: null,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            collections: 'getFeaturedCollections',
        }),
        featuredCollections() {
            return this.collections.slice(0, 3);
        },
    },
    methods: {
        setHovered(idx, setting) {
            if (setting === 'in') {
                this.hovered = idx;
            } else if (this.hovered === idx) {
                this.hovered = null;
            }
        },
        openCollection(e, navigate, collection) {
            segmentAnalytics.choose({
              userId: this.user._id,
              sessionId: this.user.sessionId,
              context: {
                section: 'featured_collections',
                view: 'home_feed',
                path: '/',
              },
              target: {
                name: 'Individual Collection',
                type: 'button',
                id: collection._id,
                title: collection.title,
              },
            });
            navigate(e);
        },
        viewAllCollections(e, navigate) {
            segmentAnalytics.choose({
              userId: this.user._id,
              sessionId: this.user.sessionId,
              context: {
                section: 'featured_collections',
                view: 'home_feed',
                path: '/',
              },
              target: {
                name: 'View All',
                type: 'button',
              },
            });
            navigate(e);
        },
    },
}

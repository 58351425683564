import Vue from 'vue';
import followApi from '@/data/api/follow';
import homeApi from '@/data/api/home-feed';
import collectionsApi from '@/data/api/collections';
import newslettersApi from '@/data/api/newsletters';
import { getModuleSettings } from '@/data/api/amplify_data';
import { log, err, toInt, isSet, isObject, isArray } from '@/data/helpers';


// initial state
const state = () => ({
  allPosts: {},
  recommendedFollows: {},
  featuredCollections: [],
  featuredTopics: [],
  featuredNewsletter: {},
  moduleSettings: {},
  affirmation: {},
  affirmationCategories: [],
  initialLoadComplete: false,
  // contianer for the multiple feeds that will exist on the homepage
  homeFeeds: {
    // featured feed
    featured: {
      items: [],
      offset: 0,
      postCounts: false,
    },
    // personalized feed
    personalized: {
      items: [],
      offset: 0,
      postCounts: false,
      follows: [],
      autoFollows: [],
    },
    new_members: {
      items: [],
      offset: 0,
      postCounts: false,
    },
    stories: {
      items: [],
      offset: 0,
      postCounts: false,
    },
    polls: {
      items: [],
      offset: 0,
      postCounts: false,
    },
    posts: {
      items: [],
      offset: 0,
      postCounts: false,
    }
  },
});

// getters for states
const getters = {
  /**
   * getFeedItems
   *
   * get the items inside the feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return array  list of items in the feed
   */
  getFeedItems: currentState => function privGetFeedItems(feedName) {
    return isObject(currentState.homeFeeds[feedName])
      ? currentState.homeFeeds[feedName].items
      : [];
  },

  getFeedPostCounts: currentState => function privGetFeedItems(feedName) {
    return isObject(currentState.homeFeeds[feedName])
      ? currentState.homeFeeds[feedName].postCounts
      : false;
  },

  isInitialLoadCompleted: currentState => currentState.initialLoadComplete,

  // get the list of auto followed items for the given feed
  getFeedAutoFollows: currentState => function privGetFeedAutoFollows(feedName) {
    if (!isObject(currentState.homeFeeds[feedName]) || !isArray(currentState.homeFeeds[feedName].autoFollows)) {
      return [];
    }

    return currentState.homeFeeds[feedName].autoFollows;
  },

  // get the list of all followed items for the given feed
  getFeedFollows: currentState => function privGetFeedFollows(feedName) {
    if (!isObject(currentState.homeFeeds[feedName]) || !isArray(currentState.homeFeeds[feedName].follows)) {
      return [];
    }

    return currentState.homeFeeds[feedName].follows;
  },

  getFeedItem: currentState => function privGetFeedItem(postId) {
    return isObject(currentState.allPosts[postId])
      ? currentState.allPosts[postId]
      : null;
  },

  isItemHearted: currentState => function privIsHearted(postId) {
    return isObject(currentState.allPosts[postId])
      ? currentState.allPosts[postId].hearted
      : false;
  },

  getFeedOffset: currentState => function privGetFeedOffset(feedName) {
    return isSet(currentState.homeFeeds[feedName]) && isObject(currentState.homeFeeds[feedName])
      ? currentState.homeFeeds[feedName].offset
      : 0;
  },

  getRecommendedFollows: currentState => currentState.recommendedFollows,

  getFeaturedCollections: currentState => currentState.featuredCollections,
  getFeaturedTopics: currentState => currentState.featuredTopics,
  getFeaturedNewsletter: currentState => currentState.featuredNewsletter,
  getModuleSettings: currentState => currentState.moduleSettings,
  getAffirmation: currentState => currentState.affirmation,
  isAffirmationSaved: currentState => currentState.affirmation.is_saved || false,
  getAffirmationCategories: currentState => currentState.affirmationCategories,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {

  async loadInitialFeed(context, { affirmationId = null }) {
    const promises = [
      context.dispatch('loadFeedItems', { feedName: 'personalized', limit: 15 }),
      context.dispatch('loadFeedItems', { feedName: 'featured', limit: 15 }),
      context.dispatch('loadFeaturedTopics'),
      context.dispatch('loadFeaturedCollections'),
      // context.dispatch('loadFeaturedNewsletter'),
      context.dispatch('loadModuleSettings'),
    ];

    if (affirmationId) {
      promises.push(context.dispatch('loadAffirmation', affirmationId));
    } else {
      promises.push(context.dispatch('loadAffirmationCategories'));
    }

    return await Promise.all(promises);
  },
  /**
   * loadFeedItems
   *
   * get all the items in a given feed, and update the store with the result
   *
   * @param object context  the context of the current store
   * @param string feedName  the name of the feed to load the items of
   * @return Promise  handles the updating of the internal store data
   */
  loadFeedItems(context, { feedName, offset, limit, types, latest = false, reset }) {

    // obtain the feed item list from the api
    const extraParams = {
      types,
    };

    if (context.state.homeFeeds[feedName].items.length && !reset) {
      extraParams.before = context.state.homeFeeds[feedName].items[context.state.homeFeeds[feedName].items.length - 1]._id;
    }

    if (latest) {
      extraParams.sort = 'latest';
    }
    if (types === 'photos') {
      extraParams.types = '';
      extraParams.filter = 'photos';
    }

    return homeApi.getFeedItems(feedName, offset, limit, extraParams, this.$nodeAxios, this.$wpAxios)
      // with successful results...
      .then((rawData) => {
        if (rawData) {
          // append the post list
          if (rawData.posts) {
            context.commit('appendFeedItems', { feedName, items: rawData.posts, perPage: limit });
          }

          if (rawData.counts) {
            context.commit('setFeedPostCounts', { feedName, postCounts: rawData.counts });
          }

          // update the list of follows that are related to this feed
          if (rawData.follows) {
            context.commit('setFeedFollows', { feedName, follows: rawData.follows });
          }
        }
      });
  },

  loadFeaturedCollections(context) {
    return collectionsApi.getShuffledCollections({}, this.$nodeAxios)
      .then((collections) => {
        context.commit('setFeaturedCollections', collections);
      })
      .catch((e) => {
        err('ERROR loadFeaturedCollection:', e);
      });
  },

  loadFeaturedNewsletter(context) {
    return newslettersApi.getNewsletter('health_and_unwellness', this.$nodeAxios)
      .then((newsletter) => {
        if (newsletter) {
          context.commit('setFeaturedNewsletter', newsletter);
        }
      }).catch((e) => {
        err('ERROR loadFeaturedNewsletter:', e);
      });
  },

  markAutoFollowsAsNotified(context, { ids }) {
    // we do not need to update the local data. if we do, the box disappears. just leave it and on page reload it will go away on it's own
    return followApi.markNotified({ ids, $nodeAxios: this.$nodeAxios })
      .catch(e => err('ERROR markAutoFollowsNotified', e));
  },

  loadSpecificItem(context, { postId }) {
    // obtain the feed item list from the api
    return homeApi.getOne(postId, this.$nodeAxios)
      // with successful results...
      .then((rawData) => {
        context.commit('addIndexedItem', { itm: rawData });
      })
      .catch((e) => {
        err('ERROR SPECIFIC ITEM:', postId, e);
      });
  },

  loadFeaturedTopics(context) {
    return homeApi.getHomeFeedTopics(this.$nodeAxios)
      .then((topics) => {
        context.commit('setFeaturedTopics', topics);
      })
      .catch((e) => {
        err('error retrieving homefeed featured topics:', e);
      });
  },

  loadModuleSettings(context) {
    return getModuleSettings()
    .then((settings) => {
      context.commit('setModuleSettings', settings);
    })
  },

  addPostToHomeFeed(context, postData) {
    const updatedPostData = { ...postData, justPosted: true };
    const prepend = true;
    const theData = { feedName: 'personalized', items: [updatedPostData], perPage: 10, prepend };
    context.commit('appendFeedItems', theData);
  },

  removePostFromHomeFeed(context, postId) {
    context.commit('removeFeedItem', postId);
  },

  reloadFeedItems(context, params) {
    // remove current stories from store
    context.commit('resetFeedItems', params.feedName);

    // load stories again (with the new filter selected in store)
    return context.dispatch('loadFeedItems', params)
      .then(() => {
        const newParams = params;
        newParams.offset = params.limit;
        newParams.limit = 40;
        context.dispatch('loadFeedItems', newParams);
      });
  },

  reloadFeedItemsWithNoReturn(context, params) {
    // remove current stories from store
    context.commit('resetFeedItems', params.feedName);

    // load stories again (with the new filter selected in store)
    return context.dispatch('loadFeedItems', params);
  },

  loadRecommendedFollows(context) {
    return homeApi.getRecommendedFollows(this.$nodeAxios)
      .then(follows => {
        context.commit('setRecommendedFollows', { follows })
      })
      .catch((e) => {
        log('Error loading Recommending follows', e);
      });
  },

  loadAffirmation(context, affirmationId) {
    return homeApi.getAffirmation(affirmationId, this.$nodeAxios)
      .then(affirmation => {
        context.commit('setAffirmation', affirmation);
      })
      .catch((e) => {
        log('Error loading affirmation', e);
      });
  },

  loadAffirmationCategories(context) {
    return homeApi.getAffirmationCategories(this.$nodeAxios)
      .then(categories => {
        context.commit('setAffirmationCategories', categories);
      })
      .catch((e) => {
        log('Error loading affirmation categories', e);
      });
  },

  saveAffirmation(context, affirmationId) {
    return homeApi.saveAffirmation(affirmationId, this.$nodeAxios)
      .then(() => { context.commit('setIsSaved', true); })
      .catch((e) => {
        log('Error saving affirmation', e);
      });
  },

  unsaveAffirmation(context, affirmationId) {
    return homeApi.unsaveAffirmation(affirmationId, this.$nodeAxios)
      .then(() => { context.commit('setIsSaved', false); })
      .catch((e) => {
        log('Error saving affirmation', e);
      });
  },
};

// helpers

/**
 * Must update post in each feed.
 */
const updateHeartCount = (currentState, incBy, params) => {
  Object.entries(currentState.homeFeeds).forEach((entry) => {
    const feedData = entry[1];
    const heartPost = feedData.items.find(x => x._id === params.postId.toString());
    if (heartPost) {
      heartPost.action_counts.hearts += incBy;
      heartPost.hearted = incBy > 0;
    }
  });
};

const updateCommentCount = (currentState, incBy, contentId) => {
  Object.entries(currentState.homeFeeds).forEach((entry) => {
    const feedData = entry[1];
    const commentPost = feedData.items.find(post => post.id === contentId);
    if (commentPost && commentPost.action_counts) {
      commentPost.action_counts.comments += incBy;
    }
  });
};

// mutate the data in the states
const mutations = {
  /**
   * subscribePost
   *
   * update the subscription status of the post, if it belongs to this feed
   *
   * @param object currentState  the state of this handler
   * @param string itemId  the id of the item that needs updating
   * @param object subscription  the subscription object from the server
   */
  subscribePost(currentState, { itemId }) {
    if (!isSet(currentState.allPosts[itemId])) {
      return;
    }

    const all = currentState.allPosts;
    all[itemId].subscribed = true;
    Vue.set(currentState, 'allPosts', all);
  },

  /**
   * unsubscribePost
   *
   * update the subscription status of the post, if it belongs to this feed
   *
   * @param object currentState  the state of this handler
   * @param string itemId  the id of the item that needs updating
   * @param object subscription  the subscription object from the server
   */
  unsubscribePost(currentState, { itemId }) {
    if (!isSet(currentState.allPosts[itemId])) {
      return;
    }

    const all = currentState.allPosts;
    all[itemId].subscribed = false;
    Vue.set(currentState, 'allPosts', all);
  },

  /**
   * appendFeedItems
   *
   * adds items to the end of the list of the specified feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to modify
   * @param array items  the list of items to add to the end of the list
   * @param object authors  id indexed list of authors
   * @param object topics  id indexed list of topics
   */
  appendFeedItems(currentState, { feedName, items, perPage, prepend }) {
    let list = currentState.homeFeeds[feedName].items || [];
    const index = currentState.allPosts;
    // cycle through the new items
    items.forEach((it, i) => {
      const itm = items[i];
      if (isObject(itm.author_id)) {
        itm.author = itm.author_id;
        itm.author_id = itm.author._id;
      }
      // add the item to the list
      if (!list.includes(itm)) {
        if (prepend) {
          list = [itm, ...list];
        } else {
          list.push(itm);
        }
      }

      if (typeof itm.id === 'string') {
        index[itm.id] = itm;
      }
    });

    // set the final list in vue
    Vue.set(currentState, 'allPosts', Object.assign({}, currentState.allPosts, index));
    Vue.set(currentState.homeFeeds[feedName], 'items', list);

    if (perPage) {
      Vue.set(currentState.homeFeeds[feedName], 'offset', currentState.homeFeeds[feedName].offset + parseInt(perPage, 10));
    } else {
      Vue.set(currentState.homeFeeds[feedName], 'offset', list.length);
    }
  },

  resetFeedItems(currentState, feedName) {
    currentState.homeFeeds[feedName].items.splice(0, currentState.homeFeeds[feedName].items.length);
  },

  removeFeedItem(currentState, postId) {
    Object.entries(currentState.homeFeeds).forEach((entry) => {
      const [name, data] = entry;
      const filtered = data.items.filter(x => x._id !== postId);
      if (filtered.length < data.items.length) {
        Vue.set(currentState.homeFeeds[name], 'items', filtered);
      }
    });
  },

  addIndexedItem(currentState, { itm }) {
    log('ADDING INDEXED ITEM', !!itm, !!itm.id, itm);
    if (itm && itm.id) {
      log('adding to index', itm);
      const allItems = currentState.allPosts;
      allItems[itm.id] = itm;
      Vue.set(currentState, 'allPosts', Object.assign({}, currentState.allPosts, allItems));
      log('set all posts', currentState.allPosts);
    }
  },

  setFeedPostCounts(currentState, { feedName, postCounts }) {
    Vue.set(currentState.homeFeeds[feedName], 'postCounts', postCounts);
  },

  /**
   * setFeedFollows
   *
   * some feed results have an additional property in them, which contains the list of follows for the user. we need to track those for the purpose of
   * showing that new auto-follow training card
   *
   * @param object currentState  the state of this handler
   * @param string feedName  the name of the feed to add the follows to
   * @param array follows  the list of follow objects to add
   */
  setFeedFollows(currentState, { feedName, follows }) {
    // fetch existing lists
    const currentFollows = currentState.homeFeeds[feedName].follows || [];
    const currentAutoFollows = currentState.homeFeeds[feedName].autoFollows || [];

    // update the lists locally
    follows.forEach((itm) => {
      currentFollows.push(itm);
      if (itm.auto) {
        currentAutoFollows.push(itm);
      }
    });

    // save the updated version of the lists
    Vue.set(currentState.homeFeeds[feedName], 'follows', currentFollows);
    Vue.set(currentState.homeFeeds[feedName], 'autoFollows', currentAutoFollows);
  },

  setRecommendedFollows(currentState, { follows }) {
    Vue.set(currentState, 'recommendedFollows', follows);
  },

  /**
   * Must update post in each feed.
   */
  addUserHeart(currentState, params) {
    updateHeartCount(currentState, 1, params);
  },
  removeUserHeart(currentState, params) {
    updateHeartCount(currentState, -1, params);
  },

  /**
   * Update comment counts
   * see comments.js module mutations
   */
  prependComment(currentState, comment) {
    updateCommentCount(currentState, 1, comment.content_id);
  },
  prependReply(currentState, { reply }) {
    updateCommentCount(currentState, 1, reply.content_id);
  },
  removeCommentFromList(currentState, params) {
    if (params.comment && params.comment.content_id) {
      updateCommentCount(currentState, -(1 + toInt(params.replyCount)), params.comment.content_id);
    }
  },

  setFeaturedCollections(currentState, collections) {
    Vue.set(currentState, 'featuredCollections', collections);
  },

  setFeaturedTopics(currentState, topics) {
    Vue.set(currentState, 'featuredTopics', topics);
  },

  setFeaturedNewsletter(currentState, newsletter) {
    Vue.set(currentState, 'featuredNewsletter', newsletter);
  },

  setModuleSettings(currentState, settings) {
    Vue.set(currentState, 'moduleSettings', settings);
  },

  setAffirmationCategories(currentState, categories) {
    Vue.set(currentState, 'affirmationCategories', categories);
  },

  setAffirmation(currentState, affirmation) {
    Vue.set(currentState, 'affirmation', affirmation);
  },

  setIsSaved(currentState, status) {
    const affirmation = { ...currentState.affirmation };
    affirmation.is_saved = status;
    Vue.set(currentState, 'affirmation', affirmation);
  },

  initalLoadCompleted(currentState) {
    Vue.set(currentState, 'initialLoadComplete', true);
  },

  updateFeedItem(currentState, post) {
    Object.entries(currentState.homeFeeds).forEach((entry) => {
      const [name, data] = entry;
      const idx = data.items.findIndex(x => x._id === post._id);
      if (idx > -1) {
        const items = [...data.items];
        items[idx] = post;
        Vue.set(currentState.homeFeeds[name], 'items', items);
      }
    });
  }
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _514c567b = () => interopDefault(import('../pages/saved/index.vue' /* webpackChunkName: "pages/saved/index" */))
const _943b7d72 = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _dee71c70 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5b34727a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b89c868 = () => interopDefault(import('../pages/u/_username.vue' /* webpackChunkName: "pages/u/_username" */))
const _47fd71da = () => interopDefault(import('../pages/conditions/index.vue' /* webpackChunkName: "pages/conditions/index" */))
const _690abd49 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _9a1ae90a = () => interopDefault(import('../pages/communities/index.vue' /* webpackChunkName: "pages/communities/index" */))
const _870ec2d8 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _38a2f4d5 = () => interopDefault(import('../pages/cookie-notice/index.vue' /* webpackChunkName: "pages/cookie-notice/index" */))
const _43120871 = () => interopDefault(import('../pages/cookie-preferences/index.vue' /* webpackChunkName: "pages/cookie-preferences/index" */))
const _a538332a = () => interopDefault(import('../pages/editorial-guidelines/index.vue' /* webpackChunkName: "pages/editorial-guidelines/index" */))
const _620649b8 = () => interopDefault(import('../pages/gdpr-applicant-hr-notice/index.vue' /* webpackChunkName: "pages/gdpr-applicant-hr-notice/index" */))
const _0de542f0 = () => interopDefault(import('../pages/groupdirectory/index.vue' /* webpackChunkName: "pages/groupdirectory/index" */))
const _0d93eaf6 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _d8e1508c = () => interopDefault(import('../pages/inbox/index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _2c75a4dd = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5f7ae4a8 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _b537f524 = () => interopDefault(import('../pages/mighty-contributor-faqs/index.vue' /* webpackChunkName: "pages/mighty-contributor-faqs/index" */))
const _2e2a0e9c = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _028fa5f6 = () => interopDefault(import('../pages/our-practices-and-principles/index.vue' /* webpackChunkName: "pages/our-practices-and-principles/index" */))
const _0283deea = () => interopDefault(import('../pages/podcastdirectory/index.vue' /* webpackChunkName: "pages/podcastdirectory/index" */))
const _64b87bfc = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _0b4daecb = () => interopDefault(import('../pages/privacy-policy-20220531/index.vue' /* webpackChunkName: "pages/privacy-policy-20220531/index" */))
const _6f91d384 = () => interopDefault(import('../pages/privacy-request/index.vue' /* webpackChunkName: "pages/privacy-request/index" */))
const _1e355fec = () => interopDefault(import('../pages/project-comments/index.vue' /* webpackChunkName: "pages/project-comments/index" */))
const _3eed8f14 = () => interopDefault(import('../pages/submit-a-story/index.vue' /* webpackChunkName: "pages/submit-a-story/index" */))
const _28a91ea9 = () => interopDefault(import('../pages/suicide-prevention-resources/index.vue' /* webpackChunkName: "pages/suicide-prevention-resources/index" */))
const _6651d654 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _2d35f6de = () => interopDefault(import('../pages/the-mighty-is-not-partnered-with-or-otherwise-affiliated-with-autism-speaks/index.vue' /* webpackChunkName: "pages/the-mighty-is-not-partnered-with-or-otherwise-affiliated-with-autism-speaks/index" */))
const _172918c6 = () => interopDefault(import('../pages/topics/index.vue' /* webpackChunkName: "pages/topics/index" */))
const _424acfe6 = () => interopDefault(import('../pages/who-we-are/index.vue' /* webpackChunkName: "pages/who-we-are/index" */))
const _7b64fe10 = () => interopDefault(import('../pages/why-join-the-mighty/index.vue' /* webpackChunkName: "pages/why-join-the-mighty/index" */))
const _7be628b5 = () => interopDefault(import('../pages/your-privacy-choices/index.vue' /* webpackChunkName: "pages/your-privacy-choices/index" */))
const _20e67495 = () => interopDefault(import('../pages/collections/CollectionLoading.vue' /* webpackChunkName: "pages/collections/CollectionLoading" */))
const _59d9a2ba = () => interopDefault(import('../pages/communities/create.vue' /* webpackChunkName: "pages/communities/create" */))
const _fa770b5e = () => interopDefault(import('../pages/newsletter/preview.vue' /* webpackChunkName: "pages/newsletter/preview" */))
const _6a8caa93 = () => interopDefault(import('../pages/newsletter/subscribe.vue' /* webpackChunkName: "pages/newsletter/subscribe" */))
const _1428974c = () => interopDefault(import('../pages/newsletter/unsubscribe.vue' /* webpackChunkName: "pages/newsletter/unsubscribe" */))
const _d2feb546 = () => interopDefault(import('../pages/podcastdirectory/Podcast.vue' /* webpackChunkName: "pages/podcastdirectory/Podcast" */))
const _ca3b49fe = () => interopDefault(import('../pages/tm/sources.vue' /* webpackChunkName: "pages/tm/sources" */))
const _78f794e8 = () => interopDefault(import('../pages/tm/moderation/_tab.vue' /* webpackChunkName: "pages/tm/moderation/_tab" */))
const _5ee85c2e = () => interopDefault(import('../pages/groups/_slug.vue' /* webpackChunkName: "pages/groups/_slug" */))
const _36e051d2 = () => interopDefault(import('../pages/tm_login/_token.vue' /* webpackChunkName: "pages/tm_login/_token" */))
const _5868c1fd = () => interopDefault(import('../pages/topic/_topicSlug/index.vue' /* webpackChunkName: "pages/topic/_topicSlug/index" */))
const _078c6b00 = () => interopDefault(import('../pages/topic/_topicSlug/collections.vue' /* webpackChunkName: "pages/topic/_topicSlug/collections" */))
const _0784769d = () => interopDefault(import('../pages/topic/_topicSlug/contributors.vue' /* webpackChunkName: "pages/topic/_topicSlug/contributors" */))
const _4f78558e = () => interopDefault(import('../pages/topic/_topicSlug/groups.vue' /* webpackChunkName: "pages/topic/_topicSlug/groups" */))
const _40aff550 = () => interopDefault(import('../pages/topic/_topicSlug/partners.vue' /* webpackChunkName: "pages/topic/_topicSlug/partners" */))
const _0dab387a = () => interopDefault(import('../pages/topic/_topicSlug/video/_storySlug.vue' /* webpackChunkName: "pages/topic/_topicSlug/video/_storySlug" */))
const _cdda43b4 = () => interopDefault(import('../pages/topic/_topicSlug/_storySlug.vue' /* webpackChunkName: "pages/topic/_topicSlug/_storySlug" */))
const _5ea1b50a = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/saved/:postId",
    component: _514c567b
  }, {
    path: "/explore/:tab",
    component: _943b7d72
  }, {
    path: "/dashboard",
    component: _dee71c70
  }, {
    path: "/poll/:pollId",
    component: _5b34727a
  }, {
    path: "/collection/:collectionId",
    component: _5b34727a
  }, {
    path: "/collection/:collectionId/poll/:contentId",
    component: _5b34727a
  }, {
    path: "/collection/:collectionId/content/:contentId",
    component: _5b34727a
  }, {
    path: "/content/:contentId",
    component: _5b34727a
  }, {
    path: "/partner/:username",
    component: _4b89c868
  }, {
    path: "/topic/:slug/list",
    component: _47fd71da
  }, {
    path: "/author/:username",
    component: _4b89c868
  }, {
    path: "/collections",
    component: _690abd49,
    name: "collections"
  }, {
    path: "/communities",
    component: _9a1ae90a,
    name: "communities"
  }, {
    path: "/conditions",
    component: _47fd71da,
    name: "conditions"
  }, {
    path: "/contact",
    component: _870ec2d8,
    name: "contact"
  }, {
    path: "/cookie-notice",
    component: _38a2f4d5,
    name: "cookie-notice"
  }, {
    path: "/cookie-preferences",
    component: _43120871,
    name: "cookie-preferences"
  }, {
    path: "/dashboard",
    component: _dee71c70,
    name: "dashboard"
  }, {
    path: "/editorial-guidelines",
    component: _a538332a,
    name: "editorial-guidelines"
  }, {
    path: "/explore",
    component: _943b7d72,
    name: "explore"
  }, {
    path: "/gdpr-applicant-hr-notice",
    component: _620649b8,
    name: "gdpr-applicant-hr-notice"
  }, {
    path: "/groupdirectory",
    component: _0de542f0,
    name: "groupdirectory"
  }, {
    path: "/health-check",
    component: _0d93eaf6,
    name: "health-check"
  }, {
    path: "/inbox",
    component: _d8e1508c,
    name: "inbox"
  }, {
    path: "/login",
    component: _2c75a4dd,
    name: "login"
  }, {
    path: "/logout",
    component: _5f7ae4a8,
    name: "logout"
  }, {
    path: "/mighty-contributor-faqs",
    component: _b537f524,
    name: "mighty-contributor-faqs"
  }, {
    path: "/notifications",
    component: _2e2a0e9c,
    name: "notifications"
  }, {
    path: "/our-practices-and-principles",
    component: _028fa5f6,
    name: "our-practices-and-principles"
  }, {
    path: "/podcastdirectory",
    component: _0283deea,
    name: "podcastdirectory"
  }, {
    path: "/privacy",
    component: _64b87bfc,
    name: "privacy"
  }, {
    path: "/privacy-policy-20220531",
    component: _0b4daecb,
    name: "privacy-policy-20220531"
  }, {
    path: "/privacy-request",
    component: _6f91d384,
    name: "privacy-request"
  }, {
    path: "/project-comments",
    component: _1e355fec,
    name: "project-comments"
  }, {
    path: "/saved",
    component: _514c567b,
    name: "saved"
  }, {
    path: "/submit-a-story",
    component: _3eed8f14,
    name: "submit-a-story"
  }, {
    path: "/suicide-prevention-resources",
    component: _28a91ea9,
    name: "suicide-prevention-resources"
  }, {
    path: "/terms-and-conditions",
    component: _6651d654,
    name: "terms-and-conditions"
  }, {
    path: "/the-mighty-is-not-partnered-with-or-otherwise-affiliated-with-autism-speaks",
    component: _2d35f6de,
    name: "the-mighty-is-not-partnered-with-or-otherwise-affiliated-with-autism-speaks"
  }, {
    path: "/topics",
    component: _172918c6,
    name: "topics"
  }, {
    path: "/who-we-are",
    component: _424acfe6,
    name: "who-we-are"
  }, {
    path: "/why-join-the-mighty",
    component: _7b64fe10,
    name: "why-join-the-mighty"
  }, {
    path: "/your-privacy-choices",
    component: _7be628b5,
    name: "your-privacy-choices"
  }, {
    path: "/collections/CollectionLoading",
    component: _20e67495,
    name: "collections-CollectionLoading"
  }, {
    path: "/communities/create",
    component: _59d9a2ba,
    name: "communities-create"
  }, {
    path: "/newsletter/preview",
    component: _fa770b5e,
    name: "newsletter-preview"
  }, {
    path: "/newsletter/subscribe",
    component: _6a8caa93,
    name: "newsletter-subscribe"
  }, {
    path: "/newsletter/unsubscribe",
    component: _1428974c,
    name: "newsletter-unsubscribe"
  }, {
    path: "/podcastdirectory/Podcast",
    component: _d2feb546,
    name: "podcastdirectory-Podcast"
  }, {
    path: "/tm/sources",
    component: _ca3b49fe,
    name: "tm-sources"
  }, {
    path: "/tm/moderation/:tab?",
    component: _78f794e8,
    name: "tm-moderation-tab"
  }, {
    path: "/groups/:slug?",
    component: _5ee85c2e,
    name: "groups-slug"
  }, {
    path: "/tm_login/:token?",
    component: _36e051d2,
    name: "tm_login-token"
  }, {
    path: "/topic/:topicSlug",
    component: _5868c1fd,
    name: "topic-topicSlug"
  }, {
    path: "/u/:username?",
    component: _4b89c868,
    name: "u-username"
  }, {
    path: "/topic/:topicSlug?/collections",
    component: _078c6b00,
    name: "topic-topicSlug-collections"
  }, {
    path: "/topic/:topicSlug?/contributors",
    component: _0784769d,
    name: "topic-topicSlug-contributors"
  }, {
    path: "/topic/:topicSlug?/groups",
    component: _4f78558e,
    name: "topic-topicSlug-groups"
  }, {
    path: "/topic/:topicSlug?/partners",
    component: _40aff550,
    name: "topic-topicSlug-partners"
  }, {
    path: "/topic/:topicSlug?/video/:storySlug?",
    component: _0dab387a,
    name: "topic-topicSlug-video-storySlug"
  }, {
    path: "/topic/:topicSlug?/:storySlug",
    component: _cdda43b4,
    name: "topic-topicSlug-storySlug"
  }, {
    path: "/",
    component: _5b34727a,
    name: "index"
  }, {
    path: "/:slug",
    component: _5ea1b50a,
    name: "slug"
  }, {
    path: "/project-comments/:contentId",
    component: _1e355fec
  }, {
    path: "/groups/:slug/content/:contentId",
    component: _5ee85c2e
  }, {
    path: "/u/:username/content/:contentId",
    component: _4b89c868
  }, {
    path: "/topic/:topicSlug/poll/:contentId",
    component: _5868c1fd
  }, {
    path: "/groups/:slug/poll/:contentId",
    component: _5ee85c2e
  }, {
    path: "/u/:username/poll/:contentId",
    component: _4b89c868
  }, {
    path: "/partner/:username/poll/:contentId",
    component: _4b89c868
  }, {
    path: "/partner/:username/content/:contentId",
    component: _4b89c868
  }, {
    path: "/topic/:topicSlug/content/:contentId",
    component: _5868c1fd
  }, {
    path: "/topic/:topicSlug/collections/collection/:collectionId/content/:contentId",
    component: _5868c1fd
  }, {
    path: "/topic/:topicSlug/collections/collection/:collectionId/poll/:contentId",
    component: _5868c1fd
  }, {
    path: "/topic/:topicSlug/collections/collection/:collectionId",
    component: _5868c1fd
  }, {
    path: "/topic/:topicSlug/video/:storySlug/content/:contentId",
    component: _cdda43b4
  }, {
    path: "/topic/:topicSlug/:storySlug/content/:contentId",
    component: _cdda43b4
  }, {
    path: "/collections/all/collection/:collectionId",
    component: _690abd49
  }, {
    path: "/collections/all/collection/:collectionId/content/:contentId",
    component: _690abd49
  }, {
    path: "/collections/all/collection/:collectionId/poll/:contentId",
    component: _690abd49
  }, {
    path: "/collections/all/",
    component: _690abd49
  }, {
    path: "/affirmation/:affirmationId",
    component: _5b34727a
  }, {
    path: "/video/:storySlug",
    component: _cdda43b4
  }, {
    path: "/:year/:month/:storySlug",
    component: _cdda43b4
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}


import { mapGetters } from 'vuex';
import get from 'lodash/get';
import Vue from 'vue';
import Navbar from "@/components/Navbar/index.vue";
import VoicesModals from "@/voices/VoicesModals/index.vue";
import LazyHydrate from 'vue-lazy-hydration';
// import CookieBanner from "@/components/CookieBanner/index.vue";
import responsiveMixin from "@/mixins/responsive";
import { tmAnalytics, sendSegmentEvent, encryptPPID } from "@/data/helpers";
import { getPagAdTargeting } from "@/data/ad-helpers";

Vue.use(responsiveMixin);

export default {
  name: 'DefaultLayout',
  data() {
    return {
      showAdSettings: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    // if showing Newsletter Preview, don't show Nav or Footer
    newsletterPreview() {
      const path =  get(this.$route, 'path', '');
      return path.includes('/newsletter/preview')
    },
    layoutId() {
      return this.newsletterPreview ? null : 'scroll-shim';
    },
  },
  components: {
    Navbar,
    VoicesModals,
    // CookieBanner,
    MightyFooter: () => import("@/components/Footer/index.vue"),
    LazyHydrate,
  },
  middleware: ["authorize"],
  async mounted() {
    if (get(this.$route, 'path', '/') !== '/logout/') {
      await this.$store.dispatch('loadBlockedUsers');
    }
  },
  methods: {
    // this setting will be used to load ads on all pages.
    getAdSettings(){
      const ppid = this.user?._id ? encodeURIComponent(encryptPPID(this.user?._id)) : null;
      return JSON.stringify({
        targeting: getPagAdTargeting(this.$route.name, this.$route.path),
        dfpId: process.env.DFP_ID,
        enable: true,
        ads: {},
        ppid
      })
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler() {
        const name = this.$route.name;
        const properties = {
          name,
          path: this.$route.path,
          title: process.browser ? document.title : '',
          url: process.browser ? window.location.href : '',
          userId: this.user._id,
          sessionId: this.user.sessionId,
        }

        // Stories and topic are configured to use a single request architecture
        const pagesWithSRA = ['topic-topicSlug-video-storySlug', 'topic-topicSlug-storySlug'];
        this.showAdSettings = !pagesWithSRA.includes(name);
        sendSegmentEvent('Page Load', properties, null, { type: "page", name });
      }
    },
  }
};

import { log } from '@/data/helpers';

/**
 * Retrieve current Newsletter offerings (ie. what user can subscribe to)
 */
function getNewsletters($nodeAxios) {
  return $nodeAxios.get('/api/v1/newsletters', {})
    .then(response => response.data)
    .catch(error => log('error: getNewsletters', error));
}

function subscribeToNewsletters(data, $nodeAxios) {
    return $nodeAxios.post('/api/v1/newsletters/subscribe', data)
    .then(response => response.data)
    .catch(error => log('error: subscribeToNewsletters', error));
}

function unsubscribeAll(cid, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/newsletters/unsubscribe/all?cid=${cid}`, {})
    .then(response => response.data)
    .catch(error => log('error: subscribeToNewsletters', error));
}

/**
 * Essentially same route as getUsersSubscriptions in @/data/api/dashboard, 
 * but that route is in Laravel and requires a parameter to identify the user, 
 * where as this is for the logged in user.
 * Returned values in data
 * list: available Newsletters a user can subscribe to
 * subscriptions: respective ids of which Newsletters they are actively subscribed
 */
function getUsersSubscriptions($nodeAxios) {
  return $nodeAxios.get(`/api/v1/newsletters/subscriptions`, {})
    .then(response => response.data)
    .catch(error => log('error: getUsersSubscriptions', error));
}

function getNewsletter(cordialId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/newsletters/${cordialId}`, {})
    .then(response => response.data)
    .catch(error => log('error: getNewsletter', error));
}

/**
 * Retrieves Published newsletters (ie. emails we sent out) for preview
 */
function getPublishedNewsletters(pubId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/newsletters/posts/${pubId}`, {})
    .then(response => response.data)
    .catch(error => log('error: subscribeToNewsletters', error));
}

export default {
    getNewsletters,
    subscribeToNewsletters,
    unsubscribeAll,
    getPublishedNewsletters,
    getUsersSubscriptions,
    getNewsletter
};

/* eslint no-console: 0 */

import $ from 'jquery';
import axios from 'axios';
import Vue from 'vue';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import CryptoJS from "crypto-js";
import he from 'he';
import { v4 as uuidv4 } from 'uuid';
import { regex } from 'vuelidate/lib/validators/common';
// import instrumentation from '@/instrumentation';
import { getObj } from './query-helpers';

const debug = process.env.NODE_ENV !== 'production';
const countOfUniqueAvatars = 8;

/**
 * Log to console if app is on debug mode (not production)
 */
function log(...logParams) {
  if (process.server) {
    console.log(...logParams);
  } else {
    if (debug || (window && window.debugLog === true)) {
      console.log(...logParams);
    }
  }
}

function err(...logParams) {
  if (process.server) {
    console.error(...logParams);
  } else {
    if (debug || (window && window.debugError === true)) {
      console.error(...logParams);
    }
  }
}

const currentUrl = siteUrl('/'); // getWindow().location.href.split('#')[0].split('?')[0];

export function getWindow() {
  if (process.server) {
    return {};
  }
  return window;
}
// type checks
function isSet(v) { return typeof v !== 'undefined' && v !== null; }
function isObject(v) { return isSet(v) && typeof v === 'object'; }
function isString(v) { return typeof v === 'string'; }
function isNumber(v) { return typeof v === 'number'; }
function isFunction(v) { return typeof v === 'function'; }
function isArray(v) { return Array.isArray(v); }

// conversions
function toInt(v) { const n = parseInt(v, 10); return !isNaN(n) ? n : 0; }

// tests
function emptyObject(v) { return !isObject(v) || !Object.keys(v).length; }

function formDataToArray(data) {
  const arr = {};
  Array.from(data.keys()).forEach((k) => {
    const val = data.getAll(k);
    if (isArray(val)) {
      if (val.length === 1) {
        arr[k] = val.shift();
      } else {
        arr[k] = val;
      }
    }
  });
  return arr;
}

function getWindowPosition() {
  const doc = document.documentElement;
  return {
    left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
    top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
  };
}

function getScrollPos(el) {
  if (el === window || el === document) {
    return getWindowPosition();
  }

  return {
    top: el.scrollTop,
    left: el.scrollTop,
  };
}

function getOffset(el) {
  return {
    top: el.offsetTop,
    left: el.offsetLeft,
  };
}

/**
 * getWindowSize
 *
 * get the current window size. usually useful for determining the device
 *
 * @return object  describes the size of the window
 */
function getWindowSize() {
  const el = document.documentElement;
  const body = document.getElementsByTagName('body')[0];
  return {
    width: el.clientWidth || body.clientWidth || window.innerWidth,
    height: el.clientHeight || body.clientHeight || window.innerHeight,
  };
}

export function isMobileWidth() {
  return getWindowSize().width < 768;
}

/**
 * Function to calculate a ratio over the window height,
 * in order to select offsets based on the window size.
 */
export function getWindowOffsetRatio(sizeRatio, mobileFactor = 1) {
  const offset = getWindowSize().height * sizeRatio;
  return isMobileWidth() ? offset * mobileFactor : offset;
}

function getOffsetRect(el, relativeTo) {
  const relEl = relativeTo || window;

  let top;
  let bottom;
  let left;
  let right;
  let rect;
  if (isFunction(el.getBoundingClientRect)) {
    rect = el.getBoundingClientRect();
  } else {
    rect = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      height: 0,
      width: 0,
    };
  }
  const winPos = getScrollPos(relEl);
  // if the parent we are comparing to is NOT the window or document, then just find the offset position
  if (relEl !== window && relEl !== document) {
    const offset = getOffset(el);
    left = offset.left;
    top = offset.top;
    right = offset.left + rect.width;
    bottom = offset.top + rect.height;
  } else {
    // add window scroll position to get the offset position
    left = rect.left + winPos.left;
    top = rect.top + winPos.top;
    right = rect.right + winPos.left;
    bottom = rect.bottom + winPos.top;
  }

  // polyfill missing 'x' and 'y' rect properties not returned
  // from getBoundingClientRect() by older browsers
  let x;
  if (rect.x === undefined) {
    x = left;
  } else {
    x = rect.x + winPos.top;
  }

  let y;
  if (rect.y === undefined) {
    y = top;
  } else {
    y = rect.y + winPos.top;
  }

  // width and height are the same
  const width = rect.width;
  const height = rect.height;

  return { left, top, right, bottom, x, y, width, height };
}

// detect if the given element is OFF screen
function isOffScreen(el) {
  const bounds = el.getBoundingClientRect();
  // too far up
  return bounds.bottom < 0 ||
    // too far down
    bounds.top > window.innerHeight ||
    // too far left
    bounds.right < 0 ||
    // too far right
    bounds.left > window.innerWidth;
}


// measure the length of the string, without html tags
function measureNonTagLength(html) {
  const measureTool = document.createElement('div');

  measureTool.innerHTML = html;
  // get raw text length
  const text = (he.decode(measureTool.textContent) || '').replace(/\u200B/g, '');

  // p-tags get replaced with double \n
  const pTagLength = $(measureTool).find('p').length - 1;

  // br-tags get replaced with single \n
  const brTagLength = $(measureTool).find('br').length;

  let exceptions = 0;
  // when there is a paragraph with nothing in it, that should count as two characters not three (<p><br></p>)
  $(measureTool).find('p').each(function privCntExcpt() {
    if (!$(this).text()) {
      exceptions += 1;
    }
  });

  return (text.length + pTagLength + brTagLength) - exceptions;
}

function sameDomainUrl(relative) {
  if (relative.match(/^https?:\/\//)) {
    return relative;
  }

  const l = window.location;
  let out = `${l.protocol}//${l.host}`;
  if (relative.substr(0, 1) !== '/') {
    const path = l.pathname.substr(-1) === '/' ? l.pathname.substr(0, l.pathname.length - 1) : l.pathname;
    out = `${out}${path}/`;
  }
  out = `${out}${relative}`;

  return out;
}

function maxLengthStringHtml(len) {
  return value => measureNonTagLength(value) <= len;
}

function minLengthStringHtml(len) {
  return value => measureNonTagLength(value) >= len;
}

const alphaNumDashUnderscore = regex('alphaNumDashUnderscore', /^[-_a-zA-Z0-9]+$/);
const notAtSymbol = regex('notAtSymbol', /^[^@\\/$]+$/im);

// figure out the vertical space occupied by the headers (admin bar and menu bar)
function contentVisibleTopOffset() {
  const navigation = document.querySelector('#site-navigation');
  let navigationData;
  // desktop
  if (getWindowSize().width >= 769) {
    navigationData = navigation ? navigation.getBoundingClientRect() : { top: 0, height: 0 };
  // mobile
  } else {
    const mobile = navigation.querySelector('#mobile-nav-toggle');
    navigationData = mobile ? mobile.getBoundingClientRect() : { top: 0, height: 0 };
  }

  return navigationData.top + navigationData.height;
}

/**
 * selectorMatches
 *
 * pure js way of detecting if the supplied element matches the supplied selector. no jQuery needed
 *
 * @param Element el  the element to test
 * @param string selector  the selector to test the element for
 * @return bool  does it match?
 */
function selectorMatches(el, selector) {
  const p = Element.prototype;
  const f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function privSelectorMatches(s) {
    return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
  };
  return f.call(el, selector);
}

/**
 * maybeCallback
 *
 * maybe call a callback, if present
 *
 * @param object obj  the object that may have a property that is a callback function
 * @param string callbackName  the name of the callback function to look for
 */
function maybeCallback(obj, callbackName, args) {
  if (!isObject(obj) || !isSet(obj[callbackName]) || !isFunction(obj[callbackName])) {
    return;
  }

  const cbArgs = args || [];
  obj[callbackName](...cbArgs);
}


let prot = '';
/**
 * protectFunction
 *
 * prevent a function's logic from being called too many times in a row. do this by delaying the actual processing, until we are certain that the handler is
 * running for the last fire of the given event
 *
 * @param function func  the function to run only once
 * @param integer delay  the amount of time to wait before checking if the function should be run
 */
function protectFunction(func, delay = 300) {
  const id1 = Math.random() * 1000000;
  const id2 = Math.random() * 100000000;
  const mine = `${id1}-${id2}`;
  prot = mine;
  setTimeout(() => {
    log('PROTECTION CHECK', mine, prot, func);
    if (mine === prot) {
      func();
    }
  }, delay);
}

function selectRange(el, start, maybeEnd) {
  const end = maybeEnd || start;
  const element = $(el).get(0);
  if (element.setSelectionRange) {
    element.focus();
    element.setSelectionRange(start, end);
  } else if (element.createTextRange) {
    const range = element.createTextRange();
    range.collapse(true);
    range.moveEnd('character', end);
    range.moveStart('character', start);
    range.select();
  }
}

function calcRetina2() {
  const window = getWindow();
  return (
    (
      window.matchMedia &&
      (
        window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches
        // eslint-disable-next-line max-len
        || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches
      )
    )
    || (
      window.devicePixelRatio && window.devicePixelRatio > 2
      && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    )
  );
}

function calcRetina3() {
  const window = getWindow();
  return (
    (
      window.matchMedia &&
      (
        window.matchMedia('only screen and (min-resolution: 384dpi), only screen and (min-resolution: 3dppx), only screen and (min-resolution: 151.2dpcm)').matches
        // eslint-disable-next-line max-len
        || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (-o-min-device-pixel-ratio: 3/1), only screen and (min--moz-device-pixel-ratio: 3), only screen and (min-device-pixel-ratio: 3)').matches
      )
    )
    || (
      window.devicePixelRatio && window.devicePixelRatio > 3
    )
  );
}

const isRetina2 = calcRetina2();
const isRetina3 = calcRetina3();

const objectFromQueryString = getObj;

export {
  debug,
  log,
  err,
  isSet,
  isObject,
  isString,
  isNumber,
  isFunction,
  isArray,
  toInt,
  objectFromQueryString,
  emptyObject,
  formDataToArray,
  getOffsetRect,
  isOffScreen,
  measureNonTagLength,
  getWindowSize,
  sameDomainUrl,
  contentVisibleTopOffset,
  getWindowPosition,
  getScrollPos,
  getOffset,
  selectorMatches,
  maybeCallback,
  maxLengthStringHtml,
  minLengthStringHtml,
  protectFunction,
  alphaNumDashUnderscore,
  notAtSymbol,
  selectRange,
  isRetina2,
  isRetina3,
};

/**
 * randomIdForAvatar
 *
 * get a random negative id for calculating the avatar
 */
function randomIdForAvatar() {
  return (0 - parseInt(Math.random() * countOfUniqueAvatars, 10)).toString();
}

function defaultAuthor() {
  // default to blank
  return {
    id: randomIdForAvatar(),
    username: '',
    display_name: '',
    avatar_url: '',
    role: 'subscriber',
  };
}

export function positionBasedOnOtherElementAndBoundingElement(ele, otherElement, boundIn, aboveBelow = 'below') {
  log('positionBasedOnOtherElementAndBoundingElement', ele, otherElement, boundIn, aboveBelow);
  const boundEle = isObject(boundIn) ? boundIn : document.getElementByTagName('body')[0];
  // bound container pos and middle measurement
  const boundPos = boundEle.getBoundingClientRect();

  // other element measurements
  const otherPos = otherElement.getBoundingClientRect();
  const otherHMiddle = otherPos.left + (otherPos.width / 2);

  // this element measurements
  const h = ele.style.height;
  // eslint-disable-next-line
  ele.style.height = '';
  const elePos = ele.getBoundingClientRect();
  // eslint-disable-next-line
  ele.style.height = h;

  // figure out the horiztonal and vertial positions, as well as the max height
  const finalPos = {
    css: {
      top: otherPos.top + otherPos.height,
      left: otherHMiddle - (elePos.width / 2),
      maxHeight: 'none',
      maxWidth: elePos.width > boundPos.width ? boundPos.width : 'none',
      height: 200,
      width: elePos.width,
    },
  };

  const assumeHeight = 200;
  let mh = assumeHeight;
  const spaceAbove = otherPos.top - boundPos.top;
  const spaceBelow = (boundPos.top + boundPos.height) - (otherPos.top + otherPos.height);
  const proposedBottom = (boundPos.top + boundPos.height) + assumeHeight;
  // is there NOT enough below
  if (aboveBelow === 'below' && finalPos.css.top + assumeHeight > boundPos.top + boundPos.height) {
    // if there is enough on top...
    if (proposedBottom < otherPos.top) {
      // eslint-disable-next-line
      aboveBelow = 'above';
    // otherwise, if there is more space on top, then use top still, but make it limited in height
    } else if (spaceAbove > spaceBelow) {
      // eslint-disable-next-line
      aboveBelow = 'above';
      finalPos.css.maxHeight = spaceAbove;
      mh = finalPos.css.maxHeight;
    // otherwise
    } else {
      finalPos.css.maxHeight = spaceBelow;
      mh = finalPos.css.maxHeight;
    }
  } else {
    log('not a condition right now');
  }

  // if anchored above, recalculate the position
  if (aboveBelow === 'above') {
    // never go above the top of the bounding container
    finalPos.css.top = Math.max(boundPos.top, otherPos.top - mh);
  }

  // account for horizontal offscreens
  if (finalPos.css.left + elePos.width > boundPos.left + boundPos.width) {
    finalPos.css.left = (boundPos.left + boundPos.width) - Math.min(elePos.width, boundPos.width);
  }
  if (finalPos.css.left < boundPos.left) {
    finalPos.css.left = boundPos.left;
  }

  // calculate the proper arrow location
  finalPos.arrowAt = {
    absolute: otherHMiddle,
    relative: otherHMiddle - finalPos.css.left,
  };

  return finalPos;
}

/**
 * clamp
 *
 * limit a number between to other numbers
 *
 * @param int num  the number to clamp
 * @param int minVal  the minimum the number should be
 * @param int maxVal  the maximum the number should be
 */
export function clamp(num, minVal, maxVal) {
  return Math.max(minVal, Math.min(maxVal, num));
}

/**
 * getUserRole
 *
 * get the actual, passed in, user-role. can be one of two keys... not sure why
 *
 * @param object user  the user object to get the role from
 * @return string  the role name
 */
export function getUserRole(user) {
  let role = user.role_slug;
  // user object from laravel
  if (!role && isSet(user.settings) && user.settings.type) {
    role = user.settings.type;
  } if (!role && isSet(user.profile) && user.profile.role) {
    role = user.profile.role;
  // standardized user object from authorFromUser() or authorFromComment()
  } else if (!role && user && user.role) {
    role = user.role;
  }
  return role;
}

export function getUserRoleType(userRole) {
  let role = 'subscriber';

  // calculate the role from the available data
  switch (userRole) {
  // contributors
  case 'contributor': role = 'contributor'; break;

  // partner types
  case 'partner':
  case 'organization': role = 'partner'; break;

  // staff
  case 'administrator':
  case 'author':
  case 'editor':
  case 'staff':
  case 'freelancer': role = 'staff'; break;

  // unknown
  default: role = `${role}`; break;
  }

  return role;
}

/**
 * getUserBadgeType
 *
 * determine the badge type, based on the user role
 *
 * @param object user  the user to test the role of
 * @return string  the badge type for this user
 */
export function getUserBadgeType(user) {
  const actualRole = getUserRole(user);
  let role = 'facebook';
  if (isObject(user) && actualRole) {
    switch (actualRole) {
    case 'staff':
    case 'organization':
    case 'partner':
    case 'contributor':
    case 'subscriber':
    default:
      role = actualRole;
      break;

    case 'editor':
    case 'administrator':
    case 'author':
    case 'freelancer':
      role = 'staff';
      break;
    }
  }
  return role;
}

/**
 * userHasProfile
 *
 * determine if the supplied user has a profile link
 *
 * @param object user  the user to test
 * @return boolean  whether they have profile or not
 */
export function userHasProfile(user) {
  let does = false;
  const could = ['staff', 'contributor', 'organization', 'partner'].indexOf(getUserBadgeType(user)) > -1;
  // if the supplied user is not an object, then skip all the logic because it is irrelevant
  if (isObject(user) && could) {
    does = !!user.username;
  }

  return does;
}

/**
 * getProfileLink
 *
 * get the profile link of the supplied user
 *
 * @param object user  the user who we need a profile link for
 * @return string  the url to the user profile
 */
export function getProfileLink(user) {
  let url = '';
  // if the user is an object.. use the information in the object to construct the url
  // if (userHasProfile(user)) {
  url = `/u/${user.username}/`;
  // }

  return url;
}

/**
 * doesUserOwnComment
 *
 * determine if the supplied user owns the supplied comment
 *
 * @param object user  the user who might own the comment
 * @param object comment  the comment that the user might own
 * @param boolean  whether the supplied user owns the supplied comment
 */
export function doesUserOwnComment(user, comment) {
  let userId = 0;
  if (isSet(user._id)) {
    userId = user._id;
  } else if (isSet(user.id)) {
    userId = user.id;
  }
  return isObject(comment.user) && comment.user._id === userId;
}

/**
 * doesUserOwnPost
 *
 * determine if the supplied user owns the supplied post
 *
 * @param object user  the user who might own the comment
 * @param object post  the post that the user might own
 * @param boolean  whether the supplied user owns the supplied comment
 */
export function doesUserOwnPost(user, post) {
  let userId = 0;
  if (isSet(user._id)) {
    userId = user._id;
  } else if (isSet(user.id)) {
    userId = user.id;
  }
  if (!post.author) {
    /* eslint no-param-reassign: "error" */
    post.author = post.author_id;
  }
  return isObject(post.author) && post.author._id === userId;
}

/**
 * canViewComment
 *
 * is this comment's content visible to the user?
 *
 * @param object comment  the comment to test
 * @param object user  the user to test the permissions for
 * @return boolean  whether or not the comment's content is viewable
 */
export function canViewComment(comment, user) {
  // default to yes
  let result = true;

  // if there is no comment, then bail now
  if (!comment && !isSet(comment.status)) {
    return false;
  }

  // if the comment is deleted or rejected, then no, outright
  if (['DELETED', 'REJECTED'].indexOf(comment.status.type) > -1) {
    result = false;
  }

  // if the comment is private, but owned by this user, then allow it
  if (['PRIVATE'].indexOf(comment.status.type) > -1) {
    if (doesUserOwnComment(user, comment)) {
      result = true;
    // if it is private but the supplied user does not own it, then fail
    } else {
      result = false;
    }
  }

  return result;
}

/**
 * getDefaultAvatarUrl
 *
 * calculate this user's default avatar url
 *
 * @param object user  the user object, with the id param exposed
 * @return string  the default avatar to use
 */
export function getDefaultAvatarUrl(user) {
  let id = user.id;
  // normalize the id to a string
  if (!isString(id)) {
    if (isSet(id)) {
      id = id.toString();
    } else {
      id = randomIdForAvatar();
    }
  }
  let realId;

  // if the id is 100% numeric, assume it is already a stringified integer
  if (id.match(/^[0-9]+$/)) {
    realId = parseInt(id, 10);
    // maybe facebook
  } else if (id.match(/_/)) {
    const parts = id.split(/_/);
    const tempId = parts.pop();
    realId = parseInt(tempId, 10);
  // otherwise assume hex
  } else {
    realId = parseInt(id.substr(-8), 16);
  }

  // get the avatar number
  const avatarNumber = (realId % 8) + 1;

  return staticPath(`avatars/avatar-0${avatarNumber}@3x.png`);
}

/**
 * authorFromUser
 *
 * create a universal author object from a session user object
 *
 * @param object user  the user object to use to create the author object
 * @return object  translated author object
 */
export function authorFromUser(user) {
  let author = defaultAuthor();
  if (isObject(user)) {
    const badgeRole = getUserBadgeType(user);
    const key = '_id';
    author = {
      id: user.id || user[`${key}`],
      username: user.username,
      display_name: user.display_name,
      avatar_url: user.profile && user.profile.avatar_thumbnail ? user.profile.avatar_thumbnail : '',
      role: badgeRole,
      profile: { flags: user.profile ? user.profile.flags : {} },
    };
  }
  return author;
}

/**
 * authorFromComment
 *
 * create a universal author object from a comment object
 *
 * @param object comment  the comment object to use to create the author object
 * @return object  translated author object
 */
export function authorFromComment(comment) {
  // default to blank
  let author = defaultAuthor();

  // if we have a facebook user who made this comment, use their info
  if (comment.metadata) {
    author = {
      id: comment.metadata && comment.metadata.fb_user_id ? comment.metadata.fb_user_id : author.id,
      username: '',
      display_name: comment.metadata.fb_name,
      avatar_url: '',
      role: 'facebook',
    };
  }

  return author;
}

/**
 * Outputs a number with "human" notation
 * Adds K or M to simplify big numbers visualization
 * @param  {int} v             numeral value
 * @param  {string} firstValue if number is 0, outputs default initial value
 * @return {string}            [description]
 */
export function humanNumberFormat(v, firstValue) {
  // get the appropriate number of significant figures from a value
  function sigFigsFromVal(val) {
    const rnd = Math.round(val).toString();
    return rnd.length === 1 ? 2 : 3;
  }
  // round a number to a value containing a number of significant digits. round up always
  function ceilSigFigs(n, sig) {
    const mult = 10 ** (sig - Math.floor(Math.log(n) / Math.LN10) - 1);
    return Math.round(Math.round(n * mult) / mult);
  }

  let letter = '';
  let num = v;

  // if the number is 0 or less, return an empty string
  if (v <= 0) {
    return firstValue || 'Be first';
  }

  // figure out the letter to use, if any
  if (v >= 1000000000000) {
    letter = 'T';
    num = ceilSigFigs(num, sigFigsFromVal(num / 1000000000000)) / 1000000000000;
  } else if (v >= 1000000000) {
    letter = 'B';
    num = ceilSigFigs(num, sigFigsFromVal(num / 1000000000)) / 1000000000;
  } else if (v >= 1000000) {
    letter = 'M';
    num = ceilSigFigs(num, sigFigsFromVal(num / 1000000)) / 1000000;
  } else if (v >= 1000) {
    letter = 'K';
    num = ceilSigFigs(num, sigFigsFromVal(num / 1000)) / 1000;
  }

  return `${num}${letter}`;
}

export function humanNumberFormatWithOptions(number, firstValue, options) {
  if (options.humanNumberFirstValue === false) {
    // eslint-disable-next-line
    firstValue = number;
  }

  if (options.humanNumber) {
    return humanNumberFormat(number, firstValue);
  }
  return number;
}

/**
 * Load a given root vue component     if the element selector is present in dom
 * @param  {Vue}    VueRootComponent   vue root component reference
 * @param  {string} elSelector         dom element selector which the component targets rendering
 * @return {Vue}                       the vue component vm instance
 */
export function loadVueComponentIntoDom(VueRootComponent, elSelector, onLoad = null) {
  function bootComponent() {
    if ($(elSelector).length) {
      $(elSelector).map((i, el) => {
        const vm = new VueRootComponent({ el, i });
        if (onLoad) {
          onLoad.call(null, vm);
        }
        return vm;
      });
    }
  }
  // bootComponent();
  $(document).ready(bootComponent);
}


export function dateDifference(old, recent) {
  // start with days, and figure out how far in the past going from longest to shortest
  // using approximates for year and month
  const daysBetween = (recent - old)/1000/60/60/24;
  if (daysBetween >= 365.25) return { q: Math.floor(daysBetween / 365.25), t: 'year' };
  if (daysBetween >= 30) return { q: Math.floor(daysBetween / 30), t: `month` };
  if (daysBetween >= 7) return { q: Math.floor(daysBetween / 7), t: `week` };
  if (daysBetween >= 1) return { q: Math.floor(daysBetween), t: `day` };

  const hours = daysBetween * 24;
  if (hours >= 1) return { q: Math.floor(hours), t: `hour` };

  const minutes = hours * 60;
  if (minutes >= 1) return { q: Math.floor(minutes), t: `minute` };

  const seconds = minutes * 60;
  return { q: Math.floor(seconds), t: `second` };
}

export function relativeDateText(dateTime) {
  const date = new Date(dateTime);
  if (!isFinite(date)) return { dateText: '', when: '' };
  const today = new Date();

  let dateText = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric"  }).format(date);

  const dateDiff = dateDifference(date, today);
  let t = dateDiff['t'] || '';
  let q = dateDiff['q'] || '';

  let w = t[0];
  if (t === 'month') w = 'mo';

  let when = `${q}${w}`;
  if (t === 'second') when ='Just Posted';
  return { dateText, when };
}

export function getDateText(post) {
  let date;
  if (isSet(post.created_at)) {
    date = new Date(post.created_at);
  } else {
    date = new Date(0);
    date.setUTCSeconds(post.pubdate_ts);
  }

  return relativeDateText(date);
}

export function urlDisplayText(url) {
  const display = url
    .replace(/https?:\/\/(www\.)?/, '') // initial www and scheme
    .replace(/\/$/, ''); // final slash
  return display;
}


export function tmAnalytics(data) {
  try {
    const analyticsData = Object.assign({
      category: undefined,
      action: undefined,
      label: undefined,
      value: undefined
    }, data);

    if (data.ga) {
      data.ga.event(analyticsData.category, analyticsData.action, String(analyticsData.label), analyticsData.value);
    }
  } catch (e) {
    if (debug) {
      log(e);
    }
  }
}

/**
 * tmCardData
 *
 * @example tmCardData('post.slug', 'default-slug');
 *
 * @param {string} pathForValue - the path to the value
 * @param defaultValue - fallback to this
 * @returns {*}
 */
export function tmCardData(pathForValue, defaultValue) {
  /* eslint-disable */
  const get = (p, o) => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o);

  try {
    /* global TM */
    if (TM.tools) {
      if (!pathForValue.startsWith('context.')) {
        pathForValue = "".concat('context.', pathForValue);
      }

      const postData = TM.tools.card_post_data();
      const value = get(pathForValue.split('.'), postData);

      if (value) {
        return value;
      }
    }
  } catch (e) {
    return defaultValue;
  }

  return defaultValue;
}

// allow adding data to an error
export function DataError(message, data) {
  this.name = 'DataError';
  this.message = message || '';
  this.data = data || {};
  this.stack = (new Error()).stack;
}
DataError.prototype = new Error;
DataError.prototype.addData = (data) => { this.data = data; };

/**
 * Generator for event data with defaults
 * First call is to create the generator with defaults.
 * The returned value is a function generator to merge event details to this set defaults
 * @param  object   defaults    initial/main defaults for the events data set
 * @return function             generator function that merges event detailed data to the initial defaults
 */
export function genAnalyticsEventDefaultData(defaults) {
  // sets default event data for the generator
  const eventData = deepmerge({
    category: null,
    action: null,
    label: null,
    context: {
      path: null,
      view: '',
      section: { name: null },
      module: { name: null },
    },
    object: {
      purpose: null,
      name: null,
      type: null,
      attributes: {},
    },
  }, defaults);

  // return the event object generator
  // accepts as many event objects for final merge
  return (...eventDetailsObjs) => {
    let eventFinalObj = eventData;
    eventDetailsObjs.forEach((dataObj) => {
      eventFinalObj = isSet(dataObj) ? deepmerge(eventFinalObj, dataObj) : eventFinalObj;
    });
    return eventFinalObj;
  };
}

/**
 * Generates a callback function for post-event dispatching of url change
 * @param  str|DOM:a  link    the link to dispatch the window
 * @param  Event      e       the event triggered (click)
 * @return function           callback function that opens the link
 */
export function genOpenLinkCallback(link, e) {
  let isNewTab = e && (e.metaKey || e.ctrlKey);

  // dom <a/> element
  if (typeof link !== 'string') {
    if ('_blank' === $(link).attr('target')) {
      isNewTab = true;
    }
    link = $(link).attr('href');
  }

  let instanceRan = false;

  // callback function to open link
  return () => {
    if (!link || instanceRan) {
      return;
    }
    if (isNewTab) {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
    instanceRan = true;
  };
}

/**
 * Generator for utm data with defaults
 * First call is to create the generator with defaults.
 * The returned value is a function generator to merge utm details to this set defaults
 * @param  object   defaults    initial/main defaults for the utm data set
 * @return function             generator function that merges utm detailed data to the initial defaults
 */
export function genAnalyticsUtmData(defaults) {
  // sets default utm data for the generator
  const utmData = Object.assign({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
  }, defaults);

  // return the utm object generator
  return (link, utmLinkObj) => {
    if (!link) {
      return '';
    }

    // acommodate hash part of the link
    let hash = '';
    if (link.indexOf('#') > -1) {
      hash = link.substr(link.indexOf('#'));
      link = link.replace(hash, '');
    }

    const paramsUri = new URLSearchParams(Object.assign({}, utmData, utmLinkObj)).toString();

    // appends utm uri to the link
    if (link.indexOf('?') === -1) {
      return `${link}?${paramsUri}/${hash}`;
    } else {
      return `${link}&${paramsUri}/${hash}`;
    }
  };
}

/**
 * hash2Obj
 *
 * convert a url hash from a string to an object
 *
 * @example
 * INPUT: https://themighty.com/1983/09/12/chris-was-born#comment=1234&per_page=10
 * OUTPUT: { comment:"1234", per_page:"10" }
 */
export function hash2Obj(str, pairDelimiter='&', kvDelimiter='=') {
  const obj = {};
  str.split(pairDelimiter)
    .forEach((pair) => {
      const kv = pair.split(kvDelimiter, 2);
      if (kv.length === 2) {
        obj[kv[0]] = kv[1];
      }
    });
  return obj;
}

/**
 * getQueryObject
 *
 * parse the window url string into a query object
 *
 * @return object  the object describing the query string
 */
export function getQueryObject() {
  let query_string = {};
  const query = window.location.search.substring(1);
  if (query) {
    const vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
      const pair = vars[i].split("=");
      if (typeof query_string[pair[0]] === "undefined") {
        query_string[pair[0]] = pair[1];
      } else if (typeof query_string[pair[0]] === "string") {
        const arr = [ query_string[pair[0]], pair[1] ];
        query_string[pair[0]] = arr;
      } else {
        query_string[pair[0]].push(pair[1]);
      }
    }
  }
  return query_string;
}

export function stripHtml(string) {
  const str = string ? he.decode(string) : '';
  return str ? str.replace(/(<([^>]+)>)/ig,'') : '';
}

/**
 * getCommentLink
 *
 * get the link of a comment, based on the comment itself and the current url
 *
 * @param object comment  the comment object that we want the link for
 * @return string  the url that is the comment link
 */
export function getCommentLink(comment) {
  return `${currentUrl}#comment=${comment.id}`;
}

export function getAbsolutePermaLink(relativeUrl) {
  return `${currentUrl}${relativeUrl}`;
}

/**
 * Gets a the link for any content type which has permaliks (ideally)
 * @param  Comment|Content item [description]
 * @return {[type]}         [description]
 */
export function getItemPermalink(item, relativeToPage) {
  relativeToPage = (typeof relativeToPage !== 'undefined') ? relativeToPage : false;

  // comment item
  if (_.has(item, 'content_id') && _.has(item, 'reply_count')) {
    return getCommentPermalink(item);
  }
  // content item
  if (_.has(item, 'slug') && _.has(item, 'type')) {

    if (relativeToPage) {
      return getContentPermalinkRelativeToPage(item);
    }

    return getContentPermalink(item);
  }
}

export function getCommentPermalink(comment, post) {
  var currentDomain;
  try {
    const _url = new URL(currentUrl);
    currentDomain = _url.protocol + "//" + _url.hostname;
  } catch(e) {
    currentDomain = "https://themighty.com";
  }

  const authorType = _.includes(['partner', 'organization'], _.get(post, 'author.role_slug')) ? 'partner' : 'u';
  const contentType = _.get(post, 'type', '') === 'POLL' ? 'poll' : 'content';
  let contentPermalink = post.id ? `${currentDomain}/${authorType}/${ (post.author || post.author_id).username }/${contentType}/${comment.content_id}` : currentUrl;
  return `${contentPermalink}#comment=${comment.id}`;
}

export function getContentPermalink(content) {
  let contentPermalink;
  // wordpress permalink
  if (_.has(content, 'card_data.card-link')) {
    contentPermalink = _.get(content, 'card_data.card-link');
  } else {
    // vuejs content permalink
    var currentDomain;
    try {
      const _url = new URL(currentUrl);
      currentDomain = _url.protocol + "//" + _url.hostname;
    } catch(e) {
      currentDomain = "https://themighty.com";
    }
    const authorType = _.includes(['partner', 'organization'], _.get(content, 'author.role_slug')) ? 'partner' : 'u';
    const contentType = _.get(content, 'type', '') === 'POLL' ? 'poll' : 'content';
    contentPermalink = `${currentDomain}/${authorType}/${(content.author || content.author_id).username}/${contentType}/${content._id}`;
  }

  return contentPermalink;
}

export function getContentPermalinkRelativeToPage(content) {
  let contentPermalink;
  // wordpress permalink
  if (_.has(content, 'card_data.card-link')) {
    contentPermalink = _.get(content, 'card_data.card-link');
  } else {
    contentPermalink = `${currentUrl}#post=${content._id}`;
  }

  return contentPermalink;
}


export function hasContentHashtags(body) {
  const HTRegex = /(^| |&nbsp;)(#[a-zA-Z0-9]*)($|&nbsp;| )/;
  const TopicRegex = /<topic[^>]*>.*?<\/topic(-new)?>/;
  return !!body.match(HTRegex) || !!body.match(TopicRegex);
}

/**
 * The card type a post should be named from
 * @param  {[type]} post [description]
 * @return {[type]}      The card type name: story, thought, question
 */
export function getPostCardType(post) {
  if (!post.type) {
    return 'item';
  }

  let type = post.type.toLowerCase().replace('wordpress_', '');
  if (type === 'post' || type === 'video') {
    type = 'story';
  }
  return type;
}

/**
 * dynamicStoreVariableAssignment
 *
 * vuejs CANNOT detect changes in dynamically added object or object keys. because of this, we have to hack that shit in there
 * @NOTE - there is no real way to simplify this and still have dynamic property updating register in the vue component
 * @reference - https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
 *
 * @param object currentState  the current state to update
 * @param string contentId  the id of the content to update the data on
 * @param mixed newData  the new data to pass to the translation function
 * @param function translation  this function performs the needed operations on the existing data, and shapes a new object to be stored in the contentId key
 */
export function dynamicStoreVariableAssignment(stateObj, primaryKey, subKey, value, assignmentTranslationFunction) {
  const original = stateObj[primaryKey];
  const assignFunction = isFunction(assignmentTranslationFunction) ? assignmentTranslationFunction : ((v) => v);
  const updated = {};
  updated[subKey] = assignFunction(value, original[subKey]);
  Vue.set(stateObj, primaryKey, Object.assign({}, original, updated));
}

export function contentPrimaryCategory(content) {
  // story has card_data category
  if (_.get(content, 'card_data.card-category_id')) {
    return {
      id: _.get(content, 'card_data.card-category_id'),
      slug: _.get(content, 'card_data.card-author-byline-url').split('/').slice(-1)[0],
    };
  }
  // voices has first visible topic
  return _.find(content.topics, postTopic => postTopic.visible);

}

export function staticPath(file) {
  const staticPath =  process.env.FE_ASSETS_URL ? `${process.env.FE_ASSETS_URL}static/` : '/static/';
  return `${staticPath}${file}`;
}

export function siteUrl(path = '/', relative = false) {
  path = path.replace(/^\/+/g, '/').replace(/\/+$/g, '');
  const url = new URL(path, process.env.SITE_BASE_URL);

  if (!(url.pathname.endsWith('.php') || url.pathname === '/')) {
    url.pathname = url.pathname.replace(/\/+$/g, '');
    url.pathname = `${url.pathname}/`;
  }

  if (relative) {
    path = `${url.pathname}${url.search}${url.hash}`;
  } else {
    path = `${url.origin}${url.pathname}${url.search}${url.hash}`;
  }

  return path;
}

const trim = function( s ) { return s.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''); };

export const cookie = {
  set: function(name, value, expire, path, domain) {
    var name = trim(name);
    if (name == '') return;
    var value = encodeURIComponent(trim(value));
    if (typeof expire == 'undefined' || expire == null || expire == 0) { expire = ''; }
    else if (Object.prototype.toString.call(expire) === '[object Date]') { expire = ';expires='+expire.toUTCString(); }
    else if (expire < 0) { var dt = new Date(); dt.setTime(dt.getTime() - 100000); expire = ';expires='+dt.toUTCString(); }
    else { var dt = new Date(); dt.setTime(dt.getTime() + expire*1000); expire = ';expires='+dt.toUTCString(); }
    if ( typeof domain == 'undefined' || domain == null ) { domain = ''; }
    else { domain = ';domain=' + domain; }
    if (typeof path == 'undefined' || path == null) { path = ''; }
    else { path = ';path='+path; }
    document.cookie = name+'='+value+expire+domain+path;
  },
  get: function(name) {
    var name = trim(name);
    if (name == '') return;
    var n,e,i,arr=document.cookie.split(';');
    for (i=0; i<arr.length; i++) { e = arr[i].indexOf('='); n = trim(arr[i].substr(0,e)); if (n == name) return trim(unescape(arr[i].substr(e+1))); }
  },
  expire: function( name ) {
    const url = new URL(currentUrl);
    document.cookie = name + "=; expires=Mon, 12 Sep 1983 00:39:00 GMT; domain=." + url.hostname + "; path=/";
    document.cookie = name + "=; expires=Mon, 12 Sep 1983 00:39:00 GMT; path=/";
  }
}

export const generateMetaTags = function({
  title, description, ogTitle, ogDescription,
  ogUrl, ogType, keywords, image, author, twTitle,
  twDescription, canonical, noIndex, noFollow
}) {
  const meta = [
    {
      hid: 'og:title',
      property: 'og:title',
      content: ogTitle || title
    },
  ];

  const link = [];

  if (canonical) {
    link.push({
      rel: 'canonical',
      href: canonical
    });
  }

  if (description) {
    meta.push({
      hid: 'description',
      name: 'description',
      content: description
    });
    meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: ogDescription || description
    });
  };

  if (keywords) {
    meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: keywords
    });
  };

  if (image) {
    meta.push({
      hid: 'og:image',
      property: 'og:image',
      content:  image
    })
  }

  if (author) {
    meta.push({
      hid: 'author',
      name: 'author',
      content:  author
    });
    meta.push({
      hid: 'og:author',
      property: 'og:author',
      content:  author
    })
  }

  meta.push({
    hid: 'twitter:card',
    name: 'twitter:card',
    content:  'summary_large_image'
  });

  meta.push({
    hid: 'twitter:site',
    name: 'twitter:site',
    content:  '@themightysite'
  });

  meta.push({
    hid: 'twitter:title',
    name: 'twitter:title',
    content: twTitle || ogTitle || title
  });

  meta.push({
    hid: 'twitter:description',
    name: 'twitter:description',
    content: twDescription || ogDescription || description
  });

  if (image) {
    meta.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: image
    });
  }

  if (ogUrl) {
    meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: ogUrl
    });
  }

  if (ogType) {
    meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: ogType
    });
  }

  if (noIndex) {
    meta.push({
      hid: 'robots-noindex',
      name: 'robots',
      content: 'noindex'
    });
  }

  if (noFollow) {
    meta.push({
      hid: 'robots-nofollow',
      name: 'robots',
      content: 'nofollow'
    });
  }


  return {
    title,
    meta,
    link
  }
}

export async function loadScript({ src }) {
  const script = document.createElement('script')
  script.src = src
  document.head.appendChild(script);
  return new Promise((resolve, reject) => {
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
  });
}


export function getStoryUrl({ post, relative = true }) {
  if ('post_type' in post) {
    if (post.post_type === 'post' && post.primary_cat_slug) {
      return siteUrl(`/topic/${post.primary_cat_slug}/${post.story_slug}`, relative);
    }

    if (post.post_type === 'video' && post.primary_cat_slug) {
      return siteUrl(`/topic/${post.primary_cat_slug}/video/${post.story_slug}`, relative);
    }
  }

  if (['WORDPRESS_POST', 'WORDPRESS_VIDEO'].includes(post.type)) {
    const topicSlug = _.get(post, 'communityTopic.slug') || post.card_data['card-author-byline-url'].replace('/', '');

    if (post.type === 'WORDPRESS_VIDEO') {
      return siteUrl(`/topic/${topicSlug}/video/${post.card_data['card-slug']}`, relative);
    }

    if (post.type === 'WORDPRESS_POST') {
      return siteUrl(`/topic/${topicSlug}/${post.card_data['card-slug']}`, relative);
    }
  }

  return '';
}

// extras for passing in any additional arguements
export async function sendSegmentEvent(event, properties, callback, extras = {}) {
  const data = Object.assign({}, {
                anonymousId: uuidv4(),
                event,
                properties,
                sentAt: new Date(),
                timestamp: new Date(),
                type: 'track',
                userId: properties.userId,
                sessionId: properties.sessionId,
                writeKey: process.env.SEGMENT_KEY,
              }, extras);

  const url = process.env.SEGMENT_URL;
  const config = {
    headers: {
        "X-API-Key": process.env.SEGMENT_API_KEY,
    },
  };

  await axios({ method: "post", url, data, ...config })
    .then(() => {
      if (callback) {
        callback();
      }
     })
    .catch((err) => log('sendSegmentEvent', err));
};

// should return an array of users mentioned in string
// each array item should return an array, index 0 being the full string, index 1 being the users id
export function getMentionedUsers(str) {
  return [...str.matchAll(/<user.*?id=\"(.+?)\".*?>.*?<\/user>/g)];
}


export function encryptPPID(plaintext, secret = "i am a secret") {
  var key = CryptoJS.enc.Utf8.parse(secret);
  let iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4));

  // Encrypt the plaintext
  var cipherText = CryptoJS.AES.encrypt(plaintext, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
    });
return cipherText.toString();
}

/*
/ function for retrieving links we want to embed on PostCards and the FullPostView
*/
export function getEmbeddedLinks(post) {
  return _.filter(
    _.get(post, "embeds.links", []), (l) => {
      const imageUrl = _.get(l, "openGraph.image.url", '');
      const hasImage = imageUrl && !_.isEmpty(imageUrl);
      if (!hasImage) return false;
      // This image is for any Mighty link that's non story essentially
      // basically if non-story, don't show
      if (imageUrl === 'https://themighty.com/wp-content/uploads/2015/10/TheMighty_logo_800x800.png') return false;
      return true;
    })
}

export function isEmail(val) {
    // just found two random regex statements for testing email, not sure there really is a fool proof method
    return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(val) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
}

export default null;
